<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
    lazy
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="BriefcaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Bedrijfsinformatie</span>
      </template>
      <company-settings-tab />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Medewerkers</span>
      </template>
      <employees-list />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="CalendarIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Rooster</span>
      </template>
      <roster />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import EmployeesList from './employees/EmployeesList.vue'
import Roster from './roster/Roster.vue'
import CompanySettingsTab from './CompanySettingsTab.vue'

export default {
  components: {
    CompanySettingsTab,
    Roster,
    EmployeesList,
    BTabs,
    BTab,
  },
}
</script>

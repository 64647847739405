<template>
  <b-card>
    <b-row>
      <b-col class="col-lg-8 offset-lg-2">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refCompanySettingsTabForm"
        >
          <b-form
            @submit.prevent="handleSubmit()"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Company name"
              rules="required"
            >
              <b-form-group
                label="Company name"
                label-for="company-name"
              >
                <b-form-input
                  id="company-name"
                  v-model="company.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Address"
              rules="required"
            >
              <b-form-group
                label="Address"
                label-for="location-address"
              >
                <b-form-input
                  id="location-address"
                  v-model="company.location.address"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Zipcode"
                  rules="required"
                >
                  <b-form-group
                    label="Zipcode"
                    label-for="location-zipcode"
                  >
                    <b-form-input
                      id="location-zipcode"
                      v-model="company.location.zipcode"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="City"
                  rules="required"
                >
                  <b-form-group
                    label="City"
                    label-for="location-city"
                  >
                    <b-form-input
                      id="location-address"
                      v-model="company.location.city"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <validation-provider
              #default="validationContext"
              name="Phonenumber"
              rules="required"
            >
              <b-form-group
                label="Phonenumber"
                label-for="location-phonenumber"
              >
                <b-form-input
                  id="location-phonenumber"
                  v-model="company.location.phonenumber"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required"
            >
              <b-form-group
                label="Email"
                label-for="location-email"
              >
                <b-form-input
                  id="location-email"
                  v-model="company.location.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      company: {
        name: null,
        location: {
          address: null,
          zipcode: null,
          city: null,
          phonenumber: null,
          email: null,
        },
      },
      required,
    }
  },
  setup() {
    const data = ref({})
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(this.company))
    }
    const {
      refCompanySettingsTabForm,
      resetForm,
      getValidationState,
    } = formValidation(resetData)

    return {
      refCompanySettingsTabForm,
      resetForm,
      getValidationState,
    }
  },
}
</script>

<style>

</style>

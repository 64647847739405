<template>
  <div>
    <table-list
      ref="tableList"
      :fields="fields"
      module="employees"
      :show-search="true"
      :sidebar-component="EmployeeListAddNew"
      sidebar-button="Add Employee"
      :sort-by="sortBy"
      sidebar-title="Add new employee"
      :show-entries-choice="false"
    >
      <template v-slot:name="data">
        <b-media vertical-align="center">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="loadData(data.item.id)"
          >
            <b-avatar
              :text="avatarText(`${data.item.name}`)"
              size="30px"
              rounded-circle
              :variant="data.item.calendarColor"
            />
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>
    </table-list>
  </div>
</template>

<script>
import {
  BMedia, BLink, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import TableList from '@/views/components/TableList.vue'
import store from '@/store'
import { computed, onUnmounted } from '@vue/composition-api'
import employeeStoreModule from './employeeStoreModule'

const EmployeeListAddNew = () => import('./EmployeeListAddNew.vue')

export default {
  components: {
    BMedia,
    BLink,
    BAvatar,
    TableList,
  },
  data() {
    return {
      sidebarComponent: 'employee-list-add-new',
      EmployeeListAddNew,
    }
  },
  setup() {
    const EMPLOYEE_STORE_MODULE_NAME = 'employee'

    // Register module
    if (!store.hasModule(EMPLOYEE_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_STORE_MODULE_NAME, employeeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.employee.tableFields)
    const sortBy = computed(() => store.state.employee.sortBy)

    return {
      fields,
      avatarText,
      sortBy,
    }
  },
  methods: {
    loadData(id) {
      this.$refs.tableList.$refs.tableListSidebar.loadData(id)
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <table-list-sidebar
      ref="tableListSidebar"
      :sidebar-component="RosterAddNewItem"
      :is-sidebar-active.sync="isSidebarActive"
      sidebar-class="sidebar-lg"
      title="Roster new item"
      module="rosters"
      @refetch-data="refetchData"
    />

    <b-card>
      <div class="d-flex flex-row justify-content-between">
        <div v-if="firstDayOfWeek">
          <h4 class="card-title">
            Week {{ moment(firstDayOfWeek).isoWeek() }}
          </h4>
          <h5 class="card-subtitle">
            {{ moment(firstDayOfWeek).format('DD') }} {{ moment(firstDayOfWeek).format('MMMM') }}
            -
            {{ moment(lastDayOfWeek).format('DD') }} {{ moment(lastDayOfWeek).format('MMMM') }}
          </h5>
        </div>
        <div class="d-flex flex-row align-items-center">
          <b-button
            type="button"
            title="Previous week"
            aria-pressed="false"
            variant="default"
            class="btn-sm"
            @click="currentDate = moment(currentDate).subtract(7, 'd').format('YYYY-MM-DD')"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="21"
            />
          </b-button>
          <b-button
            type="button"
            title="Next week"
            aria-pressed="false"
            variant="default"
            class="btn-sm"
            @click="currentDate = moment(currentDate).add(7, 'd').format('YYYY-MM-DD')"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="21"
            />
          </b-button>

          <flat-pickr
            v-model="currentDate"
            class="form-control"
            name="date"
            format="d-m-Y"
            :config="{ altInput: true, altFormat: 'j F Y', dateFormat: 'Y-m-d', wrap: true, position: 'auto center' }"
            data-input
          />
        </div>
      </div>

      <b-row>
        <b-col class="d-flex">
          <div class="roster-col label bg-light" />
          <div
            v-for="(field, index) in fields"
            :key="field"
            class="roster-col day bg-light p-1 text-center"
          >
            {{ field }}
            <div class="text-primary font-large-1">
              {{ moment(firstDayOfWeek).add(index, 'd').format('DD') }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-for="employee in employees"
        :key="employee.name"
      >
        <b-col class="d-flex">
          <div class="roster-col label p-1">
            <b-avatar
              :text="avatarText(`${employee.name}`)"
              size="30px"
              rounded-circle
              :variant="employee.calendarColor"
            />
            {{ employee.name }}
          </div>
          <div
            v-for="index in 7"
            :key="index"
            class="roster-col day d-flex align-items-center"
          >
            <b-button
              v-if="getData(employee.id, index)"
              variant="success"
              class="w-100"
              @click="loadData(2)"
            >{{ getData(employee.id, index).start }}
              -
              {{ getData(employee.id, index).end }}
            </b-button>

            <b-button
              v-else
              variant="light"
              class="w-100"
              @click="createData(employee, moment(firstDayOfWeek).add(index - 1, 'd').format('YYYY-MM-DD'))"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="text-muted"
                locale="nl"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import TableListSidebar from '@/views/components/TableListSidebar.vue'
import Api from '@/libs/api'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { avatarText } from '@core/utils/filter'

const RosterAddNewItem = () => import('./RosterAddNewItem.vue')

export default {
  components: {
    TableListSidebar,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    flatPickr,
  },
  data() {
    return {
      moment,
      RosterAddNewItem,
    }
  },
  setup() {
    const currentDate = ref('')
    const currentDatePicker = ref('')
    const firstDayOfWeek = ref()
    const lastDayOfWeek = ref()

    watch(currentDate, value => {
      firstDayOfWeek.value = moment(value).startOf('isoWeek').format('YYYY-MM-DD')
      lastDayOfWeek.value = moment(value).endOf('isoWeek').format('YYYY-MM-DD')
    })

    currentDate.value = moment().format('YYYY-MM-DD')
    currentDatePicker.value = moment().format('D MMMM YYYY')

    const fields = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag']
    const employees = ref([])
    const items = ref({})

    const isSidebarActive = ref(false)
    const refetchData = () => {}
    const fetchData = () => {
      Api.fetch('rosters', {
      })
        .then(data => {
          items.value = {}
          data['hydra:member'].forEach(value => {
            if (!(value.employee.id in items.value)) {
              items.value[value.employee.id] = {}
            }

            if (value.timeslots.length) {
              items.value[value.employee.id][moment(value.date).format('YYYY-MM-DD')] = {
                timeslots: value.timeslots,
                start: moment.utc(value.timeslots[0].startTime).format('HH:mm'),
                end: moment.utc(value.timeslots.at(-1).endTime).format('HH:mm'),
              }
            }
          })
        })
        .catch(() => {
        })
    }
    fetchData()

    const fetchResources = () => {
      Api.fetch('employees', {
      })
        .then(data => {
          employees.value = data['hydra:member']
        })
        .catch(() => {
        })
    }
    fetchResources()

    return {
      currentDate,
      fields,
      employees,
      items,

      isSidebarActive,
      refetchData,

      avatarText,
      firstDayOfWeek,
      lastDayOfWeek,
    }
  },
  methods: {
    getData(employeeId, index) {
      const date = moment(this.firstDayOfWeek).add(index - 1, 'd').format('YYYY-MM-DD')
      if (this.items[employeeId] && this.items[employeeId][date]) return this.items[employeeId][date]
      return null
    },
    loadData(id) {
      this.$refs.tableListSidebar.loadData(id)
    },
    createData(employee, date) {
      const blankData = {
        employee,
        timeslots: [],
        recurring: null,
        date,
      }
      this.$refs.tableListSidebar.setStartData(blankData)
      this.isSidebarActive = true
    },
  },
}
</script>

<style lang="scss">
.card-subtitle {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.roster-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  &.label {
    flex: 1 1 16%;
  }

  &.day {
    flex: 1 1 12%;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 .4rem;
  }
}
</style>
